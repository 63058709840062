<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.job_list_add)"
      style="margin-bottom:20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'JobAdd' })"
      >新增</el-button
    >
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, deleteJobAPI } from './api'
const columns = [
  {
    label: '岗位名称',
    prop: 'jobName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['jobName']}</p>
    }
  },
  {
    label: '岗位编码',
    prop: 'jobCode',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['jobCode']}</p>
    }
  },
  {
    label: '岗位描述',
    prop: 'remark',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['remark']}</p>
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },

  {
    label: '操作',
    width: '200',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.job_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'JobEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.job_list_del)}
            style="margin-left:10px;"
            underline={false}
            type="primary"
            onClick={() => this.handleDel(row)}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    // 列表
    async getList() {
      let params = { page: this.currentPage, pageSize: this.pageSize }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    // 删除
    handleDel({ id }) {
      this.$confirm('此操作将永久删除该岗位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteJobAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 启用/禁用
    changeStatus(row) {}
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
