import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/admin/job/page',
    method: 'get',
    params
  })
}
// delete
export function deleteJobAPI(id) {
  return http({
    url: '/admin/job/delete',
    method: 'post',
    data: { id }
  })
}
// add
export function addJobAPI(data) {
  return http({
    url: '/admin/job/add',
    method: 'post',
    data
  })
}
// edit
export function editJobAPI(data) {
  return http({
    url: '/admin/job/edit',
    method: 'post',
    data
  })
}
// detail
export function getJobDetailAPI(id) {
  return http({
    url: '/admin/job/detail',
    method: 'get',
    params: { id }
  })
}
